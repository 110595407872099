import styled, { css, keyframes } from 'styled-components';
// import { rgba } from 'polished';

import { colours } from '@a-cloud-guru/rainbow-ui';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

// border-left-color: ${props => rgba(props.colour || colours.purple600, 0.3)};
const Spinner = styled.div`
  display: block;
  height: ${props => props.size || '1.5rem'};
  width: ${props => props.size || '1.5rem'};
  border: 2px solid ${props => props.colour || colours.purple600};
  border-radius: 100%;
  animation: ${css`
    ${rotation} 0.8s infinite linear;
  `};
`;

export { Spinner };
